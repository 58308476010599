import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from 'react-router-dom';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import IframeResizer from 'iframe-resizer-react';

import { DEFAULT_ROUTE } from '../../routes/Router';
import { config } from '../../config';
import ServiceInfo from './ServiceInfo';

const AppHeader = () => {
    const navItems = [
        {
            key: 'rental',
            route: (
                <NavLink to={DEFAULT_ROUTE}>
                    <FormattedMessage id={'intl-msg:navigation-bar.sublink.rental'} />
                </NavLink>
            ),
        },
    ];

    const environment = import.meta.env.PROD ? 'production' : 'local';

    const userMenuItem = <DefaultUserMenu environment={environment} disableCookieModal />;

    return (
        <ApplicationHeader
            label={<FormattedMessage id='intl-msg:starterTemplate.moduleName' />}
            navItems={navItems}
            actionBarItems={[userMenuItem]}
        />
    );
};

export default AppHeader;
