import { createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { ErrorBoundary } from '../components/ErrorBoundary';
import AppLayout from '../layout/AppLayout';
import { suspendPageLoad } from './suspendPageLoad';

// Lazy load pages for better performance and automatically split the bundle accordingly
const RentalTab = suspendPageLoad(() => import('../pages/RentalTab'));

export const DEFAULT_ROUTE = '/rental';

export const routes = [DEFAULT_ROUTE];

export const router = createHashRouter(
    createRoutesFromElements(
        <>
            <Route element={<AppLayout />}>
                <Route path={DEFAULT_ROUTE} errorElement={<ErrorBoundary />} element={<RentalTab />} />
            </Route>

            <Route path='*' element={<Navigate to={DEFAULT_ROUTE} />} />
        </>
    )
);
