import { useRef, useState } from 'react';
import { fetchRentals, type Rental } from '../services/rentals.api';
import { fetchAssets, type FetchAssetsResult } from '../services/assets.api';
import type { Search } from '../components/searchBar/IdentifierSearchInput.types';
import { prepareHeaders } from '../configuration/tokenHandling/prepareHeaders';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/**
 * This is a faked RTK Query'esque hook so that refactoring the UI components is less annoying later.
 */
export const useLazyRentals = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState<Rental[] | undefined>(undefined);
    const [error, setError] = useState<unknown>(undefined);

    const trigger = useRef((accountId: string) => {
        setIsFetching(true);
        fetchRentals(accountId)
            .then(response => {
                setIsError(false);
                setError(undefined);
                setData(response);
                setIsSuccess(true);
            })
            .catch((e: unknown) => {
                setIsError(true);
                setError(e);
                setData(undefined);
                setIsSuccess(false);
            })
            .finally(() => {
                setIsFetching(false);
            });
    });

    return [trigger.current, { isSuccess, isError, isFetching, data, error }] as const;
};

/**
 * This is a faked RTK Query'esque hook so that refactoring the UI components is less annoying later.
 */
export const useLazyAssets = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState<FetchAssetsResult | undefined>(undefined);
    const [error, setError] = useState<unknown>(undefined);

    const trigger = useRef((search: Search) => {
        setIsFetching(true);
        fetchAssets(search)
            .then(response => {
                setIsError(false);
                setError(undefined);
                setData(response);
                setIsSuccess(true);
            })
            .catch((e: unknown) => {
                setIsError(true);
                setError(e);
                setData(undefined);
                setIsSuccess(false);
            })
            .finally(() => {
                setIsFetching(false);
            });
    });

    return [trigger.current, { isSuccess, isError, isFetching, data, error }] as const;
};

export const rentalCompaniesApi = createApi({
    reducerPath: 'rentalCompanyApi',
    baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_RENTAL_SERVICE_BASE_URL, prepareHeaders }),
    endpoints: builder => ({
        fetchRentalCompanies: builder.query<string[], void>({
            query: () => '/rental-companies',
        }),
        startRentalCommand: builder.mutation<void, { rentalCompanyId: string; assetId: string }>({
            query: ({ assetId, rentalCompanyId }) => ({
                url: '/commands/start-rental',
                method: 'POST',
                body: {
                    rental_company_asset_id: assetId,
                    renter_account_id: rentalCompanyId,
                },
            }),
        }),
        stopRentalCommand: builder.mutation<void, { rentalId: string }>({
            query: ({ rentalId }) => ({
                url: '/commands/stop-rental',
                method: 'POST',
                body: {
                    rental_id: rentalId,
                },
            }),
        }),
        sellVehicleCommand: builder.mutation<void, { rentalId: string }>({
            query: ({ rentalId }) => ({
                url: '/commands/sell-vehicle',
                method: 'POST',
                body: {
                    rental_id: rentalId,
                },
            }),
        }),
    }),
});

export const {
    useFetchRentalCompaniesQuery,
    useStartRentalCommandMutation,
    useStopRentalCommandMutation,
    useSellVehicleCommandMutation,
} = rentalCompaniesApi;
