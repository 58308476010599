import uniq from 'lodash/fp/uniq';

import {
    type Asset,
    type AssetWithDevices,
    type Device,
    type RequestedIdentifierResults,
    type Search,
    searchIdentifier,
} from '../components/searchBar/IdentifierSearchInput.types';
import { config } from '../config';
import { accessToken } from '../configuration/tokenHandling/accessToken';

export type FetchAssetsResult = {
    assetsWithDevices: AssetWithDevices[];
    requestedIdentifierStates: RequestedIdentifierResults[];
};

export const fetchAssets = async (search: Search): Promise<FetchAssetsResult> => {
    if (!search.accountId) {
        throw new Error('accountId is required');
    }

    const searchIdentifierValues = uniq(
        search.searchIdentifierValue
            .replace(/ /g, ',')
            .replace(/\t/g, ',')
            .split(',')
            .map(it => it.trim())
            .filter(it => it !== '')
    );

    const requestedIdentifierStates: RequestedIdentifierResults[] = [];

    const assets = await fetchAssetByAccountIdPaginated(search.accountId);
    let filteredAssets: Asset[] = [];

    if (
        searchIdentifierValues.length > 0 &&
        (search.searchIdentifierType === searchIdentifier.SEARCH_VIN ||
            search.searchIdentifierType === searchIdentifier.ASSET_ID)
    ) {
        for (const searchString of searchIdentifierValues) {
            const matchingAsset = assets.filter(asset => {
                if (search.searchIdentifierType === searchIdentifier.SEARCH_VIN) {
                    return searchString === asset.identification;
                } else if (search.searchIdentifierType === searchIdentifier.ASSET_ID) {
                    return searchString === asset.id;
                } else {
                    return false;
                }
            });
            if (matchingAsset.length > 0) {
                filteredAssets.push(...matchingAsset);
                requestedIdentifierStates.push({ name: searchString, found: true });
            } else {
                requestedIdentifierStates.push({ name: searchString, found: false });
            }
        }
    } else {
        filteredAssets = assets;
    }

    const assetsWithDevices: AssetWithDevices[] = await fetchDevicesAssetsInAccountId(search.accountId, filteredAssets);

    return { assetsWithDevices, requestedIdentifierStates };
};

const fetchDevicesAssetsInAccountId = async (accountId: string, assetsResponse: Asset[]) => {
    const allDevicesForAccount = await fetchDevicesForAccountPaginated(accountId);
    return assetsResponse.map(asset => {
        const devicesForAsset = allDevicesForAccount
            .filter(device => device.assetId === asset.id)
            .map(device => device.device);
        return { asset, devices: devicesForAsset };
    });
};

export const fetchAssetByAccountIdPaginated = async (accountId: string): Promise<Asset[]> => {
    if (!accountId) {
        throw new Error('accountId is required');
    }

    const assets: Asset[] = [];
    let nextLink: string | undefined =
        `${config.backend.ASSET_ADMINISTRATION}/assets?account_id=${accountId}&limit=1000`;
    while (nextLink !== undefined) {
        const response = await fetch(nextLink, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken.getAccessToken()}`,
            },
        });
        const json: any = await response.json();
        assets.push(...json.items);
        const newNextLink = json.next?.href?.link;
        if (nextLink !== newNextLink) {
            nextLink = newNextLink;
        } else {
            nextLink = undefined;
        }
    }
    return assets;
};

export const fetchDevicesForAccountPaginated = async (
    accountId: string
): Promise<{ device: Device; assetId: string }[]> => {
    try {
        let nextLink: string | undefined =
            `${config.backend.ASSET_ADMINISTRATION}/associations?account_id=${accountId}&embed=(device)&limit=1000`;
        const devices: { device: Device; assetId: string }[] = [];

        while (nextLink !== undefined) {
            const response = await fetch(nextLink, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken.getAccessToken()}`,
                },
            });
            const json: any = await response.json();

            const enrichedDevices = json.items
                .map((association: any) =>
                    association._embedded && 'device' in association._embedded
                        ? { device: association._embedded.device, assetId: association.asset_id }
                        : undefined
                )
                .filter((it: any) => it !== undefined);

            devices.push(...enrichedDevices);
            const newNextLink = json.next?.href?.link;
            if (nextLink !== newNextLink) {
                nextLink = newNextLink;
            } else {
                nextLink = undefined;
            }
        }
        return devices;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
