import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../configuration/tokenHandling/prepareHeaders';

export interface Account {
    id: string;
    name: string;
    tenant: string;
    life_cycle_state: string;
    account_type: string;
}

export const accountsApi = createApi({
    reducerPath: 'accountApi',
    baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_IAM_API_BASE_URL, prepareHeaders }),
    endpoints: builder => ({
        fetchAccountById: builder.query<Account, string>({
            query: id => `/accounts/${id}`,
        }),
    }),
});

export const { useFetchAccountByIdQuery, useLazyFetchAccountByIdQuery } = accountsApi;
