export const searchIdentifier = {
    ASSET_ID: 'asset_id',
    SEARCH_VIN: 'vin',
} as const;

export type SearchIdentifier = (typeof searchIdentifier)[keyof typeof searchIdentifier];

export interface Search {
    accountId: string;
    searchIdentifierValue: string;
    searchIdentifierType: SearchIdentifier;
}

export interface AssetWithDevices {
    asset: Asset;
    devices: Device[];
}

export type AssetStatus = 'active' | 'archived';

export type Asset = {
    id: string;
    account_id: string;
    identification: string | undefined | null;
    identification_type: string | undefined | null;
    name: string;
    brand: string | undefined | null;
    status: AssetStatus;
    type: string;
    license_plate: string | undefined | null;
    license_plate_country_code: string | undefined | null;
};

export type Device = {
    id: string;
    identification: string;
    type: string;
};

export interface RequestedIdentifierResults {
    name: string;
    found: boolean;
}
