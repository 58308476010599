import { config } from '../config';
import { accessToken } from '../configuration/tokenHandling/accessToken';

export type Rental = {
    id: string;
    state: string;
    rentalCompanyAssetId: string;
    rentalCompanyAccountId: string;
    renterAssetId: string;
    renterAccountId: string;
    startedAt: string;
};

export type RentalAPI = {
    id: string;
    state: string;
    rental_company_asset_id: string;
    rental_company_account_id: string;
    renter_asset_id: string;
    renter_account_id: string;
    started_at: string;
};

export const mapToRental = (rental: RentalAPI): Rental => {
    return {
        id: rental.id,
        state: rental.state,
        rentalCompanyAssetId: rental.rental_company_asset_id,
        rentalCompanyAccountId: rental.rental_company_account_id,
        renterAssetId: rental.renter_asset_id,
        renterAccountId: rental.renter_account_id,
        startedAt: rental.started_at,
    };
};

export const fetchRentals = async (accountId: string): Promise<Rental[]> => {
    if (!accountId) {
        throw new Error('accountId is required');
    }

    const response = await fetch(`${config.backend.RENTAL_SERVICE}/rentals?rental_company_account_id=${accountId}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
        },
    });

    if (response.status === 404) {
        return [];
    }

    if (!response.ok) {
        throw Error(`HTTP error when fetching rentals: ${response.status}`);
    }

    const json = await response.json();
    return json.items.map(mapToRental);
};

export const issueStartRentalCommand = async ({
    rentalCompanyAssetId,
    renterCompanyAccountId,
}: {
    rentalCompanyAssetId: string;
    renterCompanyAccountId: string;
}): Promise<void> => {
    const response = await fetch(`${config.backend.RENTAL_SERVICE}/commands/start-rental`, {
        method: 'POST',
        body: JSON.stringify({
            rental_company_asset_id: rentalCompanyAssetId,
            renter_account_id: renterCompanyAccountId,
        }),
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
        },
    });

    if (!response.ok) {
        throw Error(`start-rental - HTTP error: ${response.status}`);
    }
};

export const issueStopRentalCommand = async ({ rentalId }: { rentalId: string }): Promise<void> => {
    const response = await fetch(`${config.backend.RENTAL_SERVICE}/commands/stop-rental`, {
        method: 'POST',
        body: JSON.stringify({
            rental_id: rentalId,
        }),
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
        },
    });

    if (!response.ok) {
        throw Error(`stop-rental - HTTP error: ${response.status}`);
    }
};

export const issueSellVehicleCommand = async ({ rentalId }: { rentalId: string }): Promise<void> => {
    const response = await fetch(`${config.backend.RENTAL_SERVICE}/commands/sell-vehicle`, {
        method: 'POST',
        body: JSON.stringify({
            rental_id: rentalId,
        }),
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
        },
    });

    if (!response.ok) {
        throw Error(`sell-vehicle - HTTP error: ${response.status}`);
    }
};
