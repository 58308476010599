const getLogLevel = () => {
    const { localStorage } = window;
    return localStorage.getItem('logLevel');
};

/**
 * You can activate trace logging in production by adding `logLevel` to localStorage and setting it to `trace`.
 */
export const trace =
    import.meta.env.DEV || getLogLevel() === 'trace' ? (...args: any) => console.log('[src/index]', ...args) : () => {};
