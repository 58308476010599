import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../configuration/setup/store';

export interface AppState {
    sessionExpiredAcknowledged: boolean;
    accountIdsToFetch: string[];
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    accountIdsToFetch: [],
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: state => {
            state.sessionExpiredAcknowledged = true;
        },
        addAccountIdToFetch: (state, action: PayloadAction<string>) => {
            state.accountIdsToFetch.push(action.payload);
        },
    },
});

export const { hideSessionExpiredDialog, addAccountIdToFetch } = appSlice.actions;

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;
export const getAccountIdsToFetch = (state: RootState) => state.app.accountIdsToFetch;
export const hasAccountIdBeenFetched = (state: RootState, accountId: string) =>
    state.app.accountIdsToFetch.includes(accountId);

export default appSlice.reducer;
